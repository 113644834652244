html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito Sans";
  background-color: #f2f2f2;
}

a {
  color: #3c48b1;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Nunito Sans";
}
.app {
  /* padding-bottom: 30px; */
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.carousel-root {
  height: 270px;
}

.carousel .control-dots .dot {
  margin: 0 6px !important;
  box-shadow: none !important;
  border: 2px solid #000;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background-color: #f47376;
  border: 2px solid #f47376;

  /* box-shadow: 1px 1px 2px #fd8787; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* start login */
.div-background-login {
  background-color: #fff;
  height: 100vh;
}
.img-login {
  width: 100%;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
}
.div-form-login {
  padding: 20px 12%;
}
.field-login .MuiFilledInput-root {
  border-radius: 12px;
  margin-bottom: 3px !important;
  width: 100%;
  /* box-shadow: 3px 3px 3px #eee; */
}
.field-login .MuiFilledInput-root.Mui-focused {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* .btn-login {
  background-color: #64b5ec !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
  height: 45px;
} */
.text-login {
  font-size: 12px;
  text-decoration: underline;
}
/* end login */

/* start register */
.div-container {
  padding: 20px;
}
.span-rule {
  color: #64b5ec;
  text-decoration: underline;
}
/* end register */

.pointer {
  cursor: pointer;
}
.btn-radius {
  border-radius: 16px !important;
}
.div-tag {
  border-radius: 6px;
  padding: 0 5px;
}

/* start header */
.div-header {
  position: sticky;
  top: 0;
  z-index: 99;
  box-shadow: 0px 3px 3px 1px #ddd;
}
.div-header-default-layout {
  padding: 10px 20px 0 20px;
  background-image: linear-gradient(to bottom, #f19e9e, #ef5d5d);
}
.text-center {
  text-align: center;
}
.title-header {
  text-transform: uppercase;
  margin-left: 20px;
  font-weight: bold;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 18px;
  color: #fff;
}
.title-header-full {
  font-size: 18px;
  text-transform: uppercase;
  margin-left: 10px;
  font-weight: bold;
  width: 100%;
}
/* .div-header-default-layout .css-i4bv87-MuiSvgIcon-root {
  width: 45px;
  height: 45px;
  color: #f19e9e;
  padding: auto;
} */
.icon-avatar {
  position: relative;
}
.p-avatar {
  position: absolute;
  left: 11px;
  top: -10px;
  color: #fff;
  width: 36px;
  text-align: center;
  margin-bottom: 0;
}
.btn-header-login {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  color: #fff !important;
  height: 30px;
  padding: 0 5px;
  margin-right: 10px !important;
  text-transform: inherit !important;
}
.btn-header-register {
  background-color: #fff !important;
  color: #fd8787 !important;
  height: 30px;
  padding: 0 5px;
  text-transform: inherit !important;
}
/* end header */

/* start footer */
.text-footer {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  height: 100px;
}
/* end footer */

/* start default layout */
.div-default-container {
  padding: 20px;
}
/* end default layout */

/* start home */
.card-topic {
  width: 100%;
  height: 300px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin: 20px 0;
}
.img-topic {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}
/* end home */

/* start myInfo */
.div-cover {
  height: 200px;
  position: relative;
}
.div-cover img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.btn-short-upload-cover-img {
  background-color: #f19e9e !important;
  color: #fff !important;
  text-align: center;
  min-width: 30px !important;
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  right: 20px;
  bottom: 20px;
  border-radius: 50% !important;
  padding: 5px;
}
.btn-short-upload-avatar-img {
  background-color: #f19e9e !important;
  color: #fff !important;
  text-align: center;
  min-width: 25px !important;
  width: 25px !important;
  height: 25px;
  border-radius: 50% !important;
  padding: 5px;
}
.div-upload-cover-img {
  position: absolute !important;
  left: 39%;
  top: 39%;
  font-size: 10px;
}
.btn-upload-cover-img {
  background-color: #f19e9e !important;
  color: #fff !important;
  text-align: center;
  width: 100px;
}
.div-avatar {
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 3px 3px #ddd;
  padding: 0 20px 20px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.div-avatar-absolute {
  position: absolute !important;
  top: -50px;
  left: 20px;
}
.btn-submit {
  background-color: #fd8787 !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 5px !important;
  height: 45px;
}
.div-post-topic {
  border: 1px solid #ddd;
  padding: 10px;
}
.div-post-topic span {
  color: #888;
  margin: 10px;
}
.div-upload-topic {
  border: 1px solid #ddd;
  width: 100px;
  height: 100px;
  background-color: #fff;
  padding: 37px;
  border-radius: 4px;
  box-shadow: 3px 3px 3px #ddd;
}
.btn-myInfo-login {
  background-color: #fff !important;
  border: 1px solid #fd8787 !important;
  color: #fd8787 !important;
  height: 30px;
  padding: 0 5px;
  margin-right: 10px !important;
  text-transform: inherit !important;
}
.btn-myInfo-register {
  background-color: #fd8787 !important;
  color: #fff !important;
  height: 30px;
  padding: 0 5px;
  text-transform: inherit !important;
}
/* endMyInfo */
.text-grey {
  color: #888;
  font-style: italic;
  font-size: 14px;
}
.div-user-info span {
  word-wrap: break-word;
  word-break: break-word;
  margin-left: 20px;
}
.div-sub-menu {
  height: 50px;
  background-color: #fff;
  padding: 5px 0;
  margin: 0 0 -10px 0;
}
.div-btn-sub-menu {
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  width: 80px;
  height: 40px;
  padding: 5px 0;
}
.box-article-submenu {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  padding-right: 10px;
  margin-top: 0;
  margin-bottom: 0;
}
.img-article-submenu {
  min-width: 110px;
  max-width: 110px;
  min-height: 110px;
  max-height: 110px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 10px;
}
.small-img-article-submenu {
  min-width: 80px;
  max-width: 80px;
  min-height: 80px;
  max-height: 80px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 5px;
}
.div-preview-img {
  width: 100px;
  height: 100px;
  /* background-color: #ddd; */
  z-index: 1;
  position: absolute;
  bottom: 7px;
  left: 0;
  /* opacity: 0.5; */
}
.div-cate {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.div-item-cate {
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}
.div-item-cate:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.div-item-cate:last-child {
  border-bottom: 0px solid #fff !important;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.div-item-cate:hover {
  background-color: #e1e1e1;
}
.maps-marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.zalo-share-button {
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  cursor: pointer;
}
.border-zalo {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  background-color: #fff !important;
  border: 1px solid #ddd;
  border-radius: 50%;
}
#detail-textarea ::before {
  border-bottom: transparent !important;
}
.btn-ghost {
  background-color: transparent;
  color: #f47376;
  border: 1px solid #f47376;
}
.btn-ghost:hover {
  color: #fff !important;
}

@media screen and (min-width: 768px) {
  .div-header-default-layout {
    padding: 10px 0;
  }
  .div-form-login {
    padding: 20px 20%;
  }
  .div-menu-item {
    margin-left: 50px;
  }
  .div-cover {
    height: 300px;
  }
  .div-cover img {
    height: 300px;
  }
  .div-sub-menu {
    margin: 0 0 -10px -10px;
  }
}

@media screen and (min-width: 1025px) {
  /* .img-login {
    margin: 50px 20% 0 20%;
    width: 60%;
    object-fit: fill;
  } */
  .div-form-login {
    padding: 20px 30%;
  }
  .div-container {
    padding: 20px 30%;
  }
}
